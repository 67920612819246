import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home'; // Import your components

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" component={Home} />
        {/* Other routes */}
        <Route path='*' component={Home} /> {/* Catch-all route */}
      </Routes>
    </Router>
  );
};

export default App;
